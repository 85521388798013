@font-face {
  font-family: "StyreneA";
  font-weight: 300;
  src: url("./styrenea/light/styrenea-light.eot");
  src: url("./styrenea/light/styrenea-light.eot?#iefix")
      format("embedded-opentype"),
    url("./styrenea/light/styrenea-light.woff2") format("woff2"),
    url("./styrenea/light/styrenea-light.woff") format("woff"),
    url("./styrenea/light/styrenea-light.svg#styrenea-light") format("svg");
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "StyreneA";
  font-weight: 400;
  src: url("./styrenea/regular/styrenea-regular.eot");
  src: url("./styrenea/regular/styrenea-regular.eot?#iefix")
      format("embedded-opentype"),
    url("./styrenea/regular/styrenea-regular.woff2") format("woff2"),
    url("./styrenea/regular/styrenea-regular.woff") format("woff"),
    url("./styrenea/regular/styrenea-regular.svg#styrenea-regular")
      format("svg");
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "StyreneA";
  font-weight: 500;
  src: url("./styrenea/medium/styrenea-medium.eot");
  src: url("./styrenea/medium/styrenea-medium.eot?#iefix")
      format("embedded-opentype"),
    url("./styrenea/medium/styrenea-medium.woff2") format("woff2"),
    url("./styrenea/medium/styrenea-medium.woff") format("woff"),
    url("./styrenea/medium/styrenea-medium.svg#styrenea-medium") format("svg");
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "StyreneA";
  font-weight: 700;
  src: url("./styrenea/bold/styrenea-bold.eot");
  src: url("./styrenea/bold/styrenea-bold.eot");
  src: url("./styrenea/bold/styrenea-bold.eot?#iefix")
      format("embedded-opentype"),
    url("./styrenea/bold/styrenea-bold.woff2") format("woff2"),
    url("./styrenea/bold/styrenea-bold.woff") format("woff"),
    url("./styrenea/bold/styrenea-bold.svg#styrenea-bold") format("svg");
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}